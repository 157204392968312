import React from 'react';

const FlameIcon = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3784 0.832816C10.2834 0.776061 10.1748 0.746094 10.0641 0.746094C9.95344 0.746094 9.84484 0.776061 9.74983 0.832816C9.67146 0.894909 9.61858 0.983584 9.6012 1.08205C9.58383 1.18052 9.60316 1.28193 9.65554 1.3671C11.5884 4.71424 11.997 9.27139 9.35697 11.7857C8.34124 10.9321 7.53485 9.85691 6.99983 8.64282C6.02355 9.18504 5.21446 9.98434 4.66039 10.954C4.10633 11.9236 3.82847 13.0264 3.85697 14.1428C3.89683 15.127 4.13313 16.0932 4.55197 16.9847C4.97082 17.8761 5.56374 18.6748 6.29586 19.3337C7.02797 19.9926 7.88449 20.4985 8.815 20.8214C9.74551 21.1444 10.7312 21.2779 11.7141 21.2142C16.7741 21.2142 19.3984 18.0714 19.5713 14.1428C19.7755 9.42853 16.4284 3.62996 10.3784 0.832816Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.6429 14.1428C15.6429 14.9764 15.3117 15.7758 14.7223 16.3652C14.1329 16.9546 13.3335 17.2857 12.5 17.2857" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default FlameIcon;