import React from 'react';

const GridIcon = (props) => {
  return (
    <svg className={props.className} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.15625 11.25H3.09375C1.38783 11.25 0 9.86217 0 8.15625V3.09375C0 1.38783 1.38783 0 3.09375 0H8.15625C9.86217 0 11.25 1.38783 11.25 3.09375V8.15625C11.25 9.86217 9.86217 11.25 8.15625 11.25ZM3.09375 1.5C2.21494 1.5 1.5 2.21494 1.5 3.09375V8.15625C1.5 9.03506 2.21494 9.75 3.09375 9.75H8.15625C9.03506 9.75 9.75 9.03506 9.75 8.15625V3.09375C9.75 2.21494 9.03506 1.5 8.15625 1.5H3.09375ZM20.9062 11.25H15.8438C14.1378 11.25 12.75 9.86217 12.75 8.15625V3.09375C12.75 1.38783 14.1378 0 15.8438 0H20.9062C22.6122 0 24 1.38783 24 3.09375V8.15625C24 9.86217 22.6122 11.25 20.9062 11.25ZM15.8438 1.5C14.9649 1.5 14.25 2.21494 14.25 3.09375V8.15625C14.25 9.03506 14.9649 9.75 15.8438 9.75H20.9062C21.7851 9.75 22.5 9.03506 22.5 8.15625V3.09375C22.5 2.21494 21.7851 1.5 20.9062 1.5H15.8438ZM18.375 24C15.2734 24 12.75 21.4766 12.75 18.375C12.75 15.2734 15.2734 12.75 18.375 12.75C21.4766 12.75 24 15.2734 24 18.375C24 21.4766 21.4766 24 18.375 24ZM18.375 14.25C16.1005 14.25 14.25 16.1005 14.25 18.375C14.25 20.6495 16.1005 22.5 18.375 22.5C20.6495 22.5 22.5 20.6495 22.5 18.375C22.5 16.1005 20.6495 14.25 18.375 14.25ZM8.15625 24H3.09375C1.38783 24 0 22.6122 0 20.9062V15.8438C0 14.1378 1.38783 12.75 3.09375 12.75H8.15625C9.86217 12.75 11.25 14.1378 11.25 15.8438V20.9062C11.25 22.6122 9.86217 24 8.15625 24ZM3.09375 14.25C2.21494 14.25 1.5 14.9649 1.5 15.8438V20.9062C1.5 21.7851 2.21494 22.5 3.09375 22.5H8.15625C9.03506 22.5 9.75 21.7851 9.75 20.9062V15.8438C9.75 14.9649 9.03506 14.25 8.15625 14.25H3.09375Z" fill="currentColor"/>
    </svg>
  );
};

export default GridIcon;